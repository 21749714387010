(function (w, d) {
    'use strict';

    w.Tollwerk.initializeContactOwnerForm = function (formElement) {
        const contactOwnerFormHandler = new w.Tollwerk.FormHandler(formElement, formElement.action);

        // Form Submit || Button Next
        const nextButton = formElement.querySelector('.FormButton--next');

        // Form Submit || Default
        formElement.addEventListener('submit', function (event) {
            event.preventDefault();
            contactOwnerFormHandler.submitForm(
                nextButton.getAttribute('name'),
                nextButton.value,
                false
            );
        })
    };

})(typeof global !== 'undefined' ? global : window, document);