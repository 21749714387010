(function (w, d) {
    'use strict';

    /**
     * Constructor
     *
     * @param {Element} targetElement Form element
     * @param {String} formAction Form URL
     * @constructor
     */
    const FormHandler = function (targetElement, formAction) {
        this.targetForm = targetElement;
        this.formAction = formAction;
    };

    /**
     * Create an XHR request
     *
     * @return {*}
     */
    FormHandler.prototype.getXHR = function () {
        try {
            return new XMLHttpRequest();
        } catch (e) {
        }
        try {
            return new ActiveXObject("Msxml2.XMLHTTP.6.0");
        } catch (e) {
        }
        try {
            return new ActiveXObject("Msxml2.XMLHTTP.3.0");
        } catch (e) {
        }
        try {
            return new ActiveXObject("Microsoft.XMLHttp");
        } catch (e) {
        }
        console.log("Could not find XMLHttpRequest");
    };

    /**
     * Submit the form via AJAX
     *
     * @param {String} uri URI
     * @param {Function} callback Callback after successfull submission
     * @param {FormData} data Form variables
     */
    FormHandler.prototype.makeRequest = function (uri, callback, data = null) {
        const that = this;
        this.toggleAnimation('on');
        const xhr = this.getXHR();
        const method = data ? 'POST' : 'GET';

        xhr.open(method, uri, true);
        xhr.onload = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    callback(xhr.responseText);
                    that.toggleAnimation('off');
                } else {
                    console.error(xhr.statusText);
                }
            }
        };

        xhr.onerror = function (e) {
            console.error(xhr.statusText);
        };

        xhr.send(data);
    };

    /**
     * Submit handler
     *
     * @param {String} targetSelector
     * @param {String} targetPage Next step index
     * @param {Boolean} multipart
     */
    FormHandler.prototype.submitForm = function (targetSelector, targetPage = '', multipart = true) {
        const that = this;
        const formData = new FormData(that.targetForm);

        // Modal Form Primer
        if (targetPage !== '' && d.querySelector('button[name="' + targetSelector + '"]')) {
            formData.append(targetSelector, targetPage);
        }

        let url = (multipart) ? this.formAction + '&type=2703' : this.formAction + '&type=2704';

        /**
         * [ JS - enabled  ] https://fh.tollwerk.de/location?tx_twfh_locationdetails%5Baction%5D=details&tx_twfh_locationdetails%5Bcontroller%5D=Location&cHash=80fc0c9614e61fbb2eb21074f556ec37&type=2704
         * [ JS - disabled ] https://fh.tollwerk.de/location?tx_twfh_locationdetails%5Baction%5D=details&tx_twfh_locationdetails%5Bcontroller%5D=Location&&cHash=1af09b3ad406e44494b2f18d71f658b1
         */
        this.makeRequest(url, function (data) {
            const parser = new DOMParser();
            const partial = parser.parseFromString(data, 'text/html');

            let partialElement, targetElement;

            if (multipart) {
                partialElement = partial.querySelector('div');
                targetElement = d.querySelector('#tw-lightbox-content-inner');

                targetElement.querySelector('div').remove();

                if (partialElement.hasAttribute('data-mutate-recursive')) {
                    targetElement.appendChild(partialElement);

                    // Property Map
                    if (targetElement.querySelector('#propertyMap')) {
                        w.Tollwerk.initializePropertyMap();
                    }

                    // Etc
                    if (targetElement.querySelector('XXX')) {
                        // ...
                    }

                } else {
                    // Reload the page
                    if (partial.querySelector('#c64')) {
                        targetElement.appendChild(partial.querySelector('#c64'));
                    } else if (partial.querySelector('#c60')) {
                        targetElement.appendChild(partial.querySelector('#c60'));
                    } else if (partial.querySelector('#c65')) {
                        // Proposal Confirmation
                        targetElement.appendChild(partial.querySelector('#c65'));
                    } else if (partial.querySelector('#c80')) {
                        // Login success
                        targetElement.appendChild(partial.querySelector('#c80'));
                        setTimeout(function () {
                            w.location.href = '/';
                        }, 1000);
                    } else {
                        w.location.href = '/';
                    }
                }

            } else {

                // Discussion
                targetElement = d.querySelector('.CommentList');
                if (targetElement) {
                    d.querySelector('#commentForm-text').value = '';
                    partialElement = partial.querySelector('.CommentList ol');
                    targetElement.querySelector('ol').remove();
                    targetElement.appendChild(partialElement);
                    w.Tollwerk.initializeLocationDiscussionReply(targetElement);
                    w.Tollwerk.initializeLocationReplies(targetElement);
                }

                // Contact Owner Form
                targetElement = d.querySelector('.ContactOwner__content');
                if (targetElement) {
                    partialElement = partial.querySelector('.ContactOwner__content .ContactOwner__content--inner');
                    targetElement.querySelector('.ContactOwner__content--inner').remove();
                    targetElement.appendChild(partialElement);
                }

                // Etc
                targetElement = d.querySelector('XXX');
                if (targetElement) {
                    // ...
                }
            }
        }, formData);
    };

    /**
     * Toggle a class during submission
     *
     * @param {String} state Submitting
     */
    FormHandler.prototype.toggleAnimation = function (state) {
        const targetElement = d.querySelector('.Lightbox .Lightbox__content');
        if (targetElement) {
            targetElement.classList[['remove', 'add'][(state === 'on') * 1]]('Loading');
        }
    };

    // Export as CommonJS module
    if (typeof exports !== 'undefined') {
        exports.FormHandler = FormHandler;

        // Else create a global instance
    } else {
        w.Tollwerk.FormHandler = FormHandler;
    }
})(typeof global !== 'undefined' ? global : window, document);
