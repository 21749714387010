(function (w, d) {
    'use strict';

    w.Tollwerk.Observer.register('#FilterButton', function (filterButton) {
        const button = d.createElement('button');
        button.className = filterButton.className;
        button.innerHTML = filterButton.innerHTML;
        button.setAttribute('aria-hidden', 'true');

        filterButton.parentNode.appendChild(button);
        filterButton.parentNode.removeChild(filterButton)
    })
})(window, document);
