(function (w) {
    w.Tollwerk.Observer.register('a[href^="http"][target=_blank]', function (observedElement) {
        observedElement.classList.add('externalLink');

        const inlinesvgwrapper = document.createElement("svg");
        const inlinesvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");

        inlinesvgwrapper.classList.add('Icon');
        inlinesvgwrapper.classList.add('Icon--inline');
        inlinesvgwrapper.classList.add('Icon--external-link');

        inlinesvg.setAttribute("viewBox", "0 0 576 512");
        inlinesvg.setAttribute("focusable", "false");
        inlinesvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

        path.setAttributeNS(null, 'd', 'M448 279.196V464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h248a24 24 0 0 1 16.97 7.029l16 16C344.09 102.149 333.382 128 312 128H64v320h320V295.196c0-6.365 2.529-12.47 7.029-16.971l16-16C422.148 247.106 448 257.814 448 279.196zM576 37.333C576 16.715 559.285 0 538.667 0H380c-15.464 0-28 12.536-28 28v17.885c0 15.766 13.011 28.424 28.772 27.989l67.203-1.906L199.09 319.09c-9.429 9.363-9.457 24.605-.061 34.001l23.879 23.879c9.396 9.396 24.639 9.369 34.001-.06l247.122-248.885-1.906 67.203c-.434 15.76 12.224 28.772 27.99 28.772H548c15.464 0 28-12.536 28-28V37.333z');

        observedElement.appendChild(inlinesvgwrapper);
        inlinesvgwrapper.appendChild(inlinesvg);
        inlinesvg.appendChild(path)

        observedElement.innerHTML = observedElement.innerHTML;
    })
})(window);
