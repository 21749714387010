(function (w, d) {
    'use strict';

    // Register Lightbox links
    w.Tollwerk.Observer.register('.SubmitLocation', (link) => {
        link._modalBaseUrl = link.getAttribute('data-modal-url');
        w.Tollwerk.Geolocation.register(position => {
            const enhancedModalUrl = link._modalBaseUrl + `&latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`;
            link.setAttribute('data-modal-url', enhancedModalUrl);
        })
    });
})(typeof global !== 'undefined' ? global : window, document);
