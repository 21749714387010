(function (w, d) {
    /**
     * A simple Lightbox
     *
     * @param userOptions
     * @returns {*}
     * @constructor
     */
    Lightbox = function (userOptions) {
        // Return null of required DOM elements could not be found
        this.lightbox = d.getElementById('tw-lightbox');

        this.lightboxContent = d.getElementById('tw-lightbox-content');
        this.lightboxContentInner = d.getElementById('tw-lightbox-content-inner');
        this.lightboxCloseButton = d.getElementById('tw-lightbox-close');

        this.lightboxAriaHeader = d.getElementById('tw-lightbox-aria-header');
        this.lightboxAriaFooter = d.getElementById('tw-lightbox-aria-footer');

        if (!this.lightbox || !this.lightboxContent || !this.lightboxAriaHeader) {
            return null;
        }

        // Set rest of object properties
        this.ariaOpen = this.lightbox.attributes['data-aria-open'].value;
        this.ariaClose = this.lightbox.attributes['data-aria-close'].value;
        this.ariaClosed = this.lightbox.attributes['data-aria-closed'].value;
        this.active = false;
        this.themes = ['light', 'dark'];
        this.currentTheme = '';

        // Define default options and overwrite/merge with options parameter
        this.options = {
            theme: null,
            closeOnOutsideClick: false,
            closeButton: true,
            solidBackground: false,
        };
        this.setOptions(userOptions);

        // Close on ESC key
        d.addEventListener('keydown', function (event) {
            if (event.keyCode == 27) {
                this.close();
            }
        }.bind(this));

        // Close on click outside of lightboxContent
        this.lightbox.addEventListener('click', function (event) {
            if (this.options.closeOnOutsideClick) {
                this.close();
            }
        }.bind(this));

        // Close on click on close button
        this.lightboxCloseButton.addEventListener('click', function (event) {
            this.close();
        }.bind(this));

        // Prevent closing on click inside lightboxContent by stopping event bubbling
        this.lightboxContent.addEventListener('click', function (event) {
            event.stopPropagation();
            event.cancelBubble = true;
        });

        return this;
    }

    /**
     * Set the theme
     *
     * @param {string} theme
     * @returns {Lightbox}
     */
    Lightbox.prototype.setTheme = function (theme) {
        for (var i = 0, len = this.themes.length; i < len; i++) {
            this.lightbox.classList.remove('Lightbox--theme-' + this.themes[i]);
        }

        if (this.themes.indexOf(theme) >= 0) {
            this.currentTheme = theme;
            this.lightbox.classList.add('Lightbox--theme-' + this.currentTheme);
            return this;
        }

        this.lightbox.classList.add('Lightbox--theme-default');
        this.currentTheme = null;
        return this;
    }

    /**
     * Set options
     *
     * @param options
     * @returns null
     */
    Lightbox.prototype.setOptions = function (options = {}) {
        // var options = options || {};

        // Merge this.options with options parameter
        this.options = Object.assign(this.options, options);

        // closeButton
        //console.log(this.options.closeButton);
        if (this.options.closeButton) {
            this.lightboxCloseButton.removeAttribute('hidden');
            this.lightboxCloseButton.removeAttribute('aria-hidden');
        } else {
            this.lightboxCloseButton.setAttribute('hidden', true);
            this.lightboxCloseButton.setAttribute('aria-hidden', true);
        }

        if (this.options.solidBackground) {
            this.lightbox.classList.add('Lightbox--solid-background');
        } else {
            this.lightbox.classList.remove('Lightbox--solid-background');
        }

        // theme
        this.setTheme(this.options.theme);
        return this;
    }

    /**
     * Set lightbox active or inactive
     * @param {boolean} active
     * @returns {boolean} True if lightbox was set active. False if lightbox was set inactive
     */
    Lightbox.prototype.setActive = function (active) {
        var active = active || false;
        if (active) {
            this.active = true;
            this.lightbox.classList.remove('Lightbox--hidden');
            return this;
        }

        this.active = false;
        this.lightbox.classList.add('Lightbox--hidden');
        return this;
    }

    /**
     * Open lightbox with content. If no content given, lightbox gets closed.
     * @param {string} content
     */
    Lightbox.prototype.open = function (content, title, buttons) {

        var content = content || '';
        var title = title || '';
        if (!content) {
            this.setActive(false);
            this.setRegularTabIndexes(true);
        }

        //give lightbox dialog open attribute
        this.lightbox.setAttribute('open', 'open');

        this.lightboxAriaHeader.setAttribute('aria-label', this.ariaOpen + (title ? ': ' + title : ''));
        this.lightboxAriaFooter.setAttribute('aria-label', this.ariaClose);
        this.lightboxContentInner.innerHTML = content;
        this.setActive(true);
        this.setRegularTabIndexes(false);
        d.documentElement.style.overflow = 'hidden';


        //disable all focusable elements not inside of the lightbox
        var allfocusable = ally.query.focusable();
        var lightboxfocusable = ally.query.focusable({
            context: '.Lightbox'
        });

        // console.log(lightboxfocusable)

        var unfocus = allfocusable.filter(function (e) {
            return !lightboxfocusable.includes(e);
        });
        // console.log(unfocus);

        unfocus.forEach(function (item) {
            ally.element.disabled(item, true);
            item.setAttribute('data-ally-disabled', 'true');
            // 1
        })
        return this;
    }

    /**
     * Close lightbox. Remove content.
     */
    Lightbox.prototype.close = function () {

        //remove lightbox dialog open attribute
        this.lightbox.removeAttribute('open');

        this.setActive(false);
        this.lightboxAriaHeader.setAttribute('aria-label', this.ariaClosed);
        this.lightboxAriaFooter.removeAttribute('aria-label');
        this.lightboxContentInner.innerHTML = '';
        this.setRegularTabIndexes(true);
        d.documentElement.style.removeProperty('overflow');

        //disable all focusable elements not inside of the lightbox
        var alldisabled = d.querySelectorAll("[data-ally-disabled='true']");
        // console.log(alldisabled)
        alldisabled.forEach(function (item) {
            ally.element.disabled(item, false);
            item.setAttribute('data-ally-disabled', 'false');
        })
        return this;
    }

    /**
     *
     * @param {boolean} state   If true, enables all regular tabindexes. If false disables all regular tab indexes.
     */
    Lightbox.prototype.setRegularTabIndexes = function (enable) {

        // Hide regular content for screenreaders
        var contents = d.querySelectorAll('body > *:not(#tw-lightbox)');
        for (var i = 0, len = contents.length; i < len; i++) {
            if (enable) {
                contents[i].removeAttribute('aria-hidden');
            } else {
                contents[i].setAttribute('aria-hidden', true);
            }
        }

        // Disable tabindexes for all elements not inside the lightbox
        var elements = [];
        var elementSelectors = [
            'body > input, body > *:not(#tw-lightbox) input',
            'body > button, body > *:not(#tw-lightbox) button',
            'body > select, body > *:not(#tw-lightbox) select',
            'body > a, body > *:not(#tw-lightbox) a'
        ];
        for (var i = 0, len = elementSelectors.length; i < len; ++i) {
            var foundElements = d.querySelectorAll(elementSelectors[i]);
            for (var j = 0, jLen = foundElements.length; j < jLen; ++j) {
                elements.push(foundElements[j]);
            }
        }
        for (var i = 0, len = elements.length; i < len; i++) {
            if (enable) {
                elements[i].removeAttribute('tabindex');
            } else {
                elements[i].setAttribute('tabindex', '-1');
            }
        }
    }

    /**
     * Create a lightbox singleton instance
     *
     * @param {Object} options Lightbox options
     * @returns {Lightbox}
     */
    const lightboxSingleton = function lightboxSingleton(options) {
        if (!d._lightbox) {
            d._lightbox = new Lightbox(options);
        }

        d._lightbox.setOptions(options);
        return d._lightbox;
    };

    // Export as CommonJS module
    if (typeof exports !== 'undefined') {
        exports.Lightbox = lightboxSingleton;

        // Else create a global instance
    } else {
        w.Tollwerk.Lightbox = lightboxSingleton;
    }

    /**
     * Return a callback for loading lightbox content
     *
     * @param {Element} link Link element
     * @return {Function} Callback
     */
    const loadLightboxContentCallback = function loadLightboxContent(link) {
        return (event) => {
            event.preventDefault();
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    const container = d.createElement("div");
                    container.innerHTML = this.responseText;

                    // Initialize slider content in lightbox
                    if (container.querySelector('.Slider')) {
                        setTimeout(function () {
                            initialiseSlider('#tw-lightbox');
                        }, 100);
                    }

                    w.Tollwerk.Lightbox({ closeOnOutsideClick: false, theme: 'light' }).open(this.response);
                }
            };

            xhttp.open('GET', link.getAttribute('data-modal-url') || link.href, true);
            xhttp.send();
        }
    }

    // Register Lightbox links
    w.Tollwerk.Observer.register('a[data-modal-url],' +
        '.Lightbox .FormRow--forgot-password a,' +
        '.Lightbox .Form--login-forgot-password a,' +
        '.Lightbox .Form--login-forgot-password .FormButton CallToAction',
        (link) => {
            link.addEventListener('click', loadLightboxContentCallback(link));
        });
})(window, document);
