(function (w) {
    'use strict';

    w.Tollwerk.Observer.register('.Lightbox #frontendUserLogin', function (observedElement) {

        const loginFormHandler = new w.Tollwerk.FormHandler(observedElement, observedElement.action);

        observedElement.addEventListener('submit', function(event) {
            event.preventDefault();
            loginFormHandler.submitForm();
        });
    });

    w.Tollwerk.Observer.register('.Form--login-forgot-password', function (observedElement) {

        const loginFormHandler = new w.Tollwerk.FormHandler(observedElement, observedElement.action);

        observedElement.addEventListener('submit', function(event) {
            event.preventDefault();
            loginFormHandler.submitForm();
        });
    });

})(typeof global !== 'undefined' ? global : window);

