'use strict';

var TwFilter = (function () {
    return {
        toggleFilter: function () {
            document.querySelector('.LocationSearch--filter .Form__body').classList.toggle('LocationSearch__filter--hidden');
        },
        toggle: function () {
            document.querySelector('.LocationSearch--filter .Form__body').classList.toggle('LocationSearch__filter--hidden');
            document.querySelector('html').classList.toggle('filter-open');
        },
        isActive: function () {
            return document.querySelector('html').classList.contains('filter-open');
        }
    };
}());

window.addEventListener('load', function () {
    // Default hiding filters
    var filterBody = document.querySelector('.LocationSearch--filter .Form__body');
    if (filterBody) {
        filterBody.classList.add('LocationSearch__filter--hidden');
    }

    // Filter Button Event Binding
    var filterButtons = document.querySelectorAll('.LocationSearch__filter--toggle .CallToAction__Filter');
    if (filterButtons) {
        filterButtons.forEach(function (filterButton) {
            filterButton.addEventListener('click', function (e) {
                e.preventDefault();
                TwFilter.toggle();
            });
        });
    }
});
