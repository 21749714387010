(function (w, d) {
    'use strict';

    if (((typeof exports !== 'undefined') && exports.Geolocation) || w.Tollwerk.Geolocation) {
        return;
    }

    // Current position coordinates
    w.Tollwerk.position = null;

    /**
     * Geolocation observer constructor
     *
     * @constructor
     */
    function Geolocation() {
        this.callbacks = [];
    }

    /**
     * Register a new selector / callback pair
     *
     * @param {String} selectors Selectors
     * @param {Function} callback Callback
     */
    Geolocation.prototype.register = function (callback) {
        this.callbacks.push(callback);
    }

    /**
     * Update the current geolocation coordinates
     *
     * @param {Object} position Position
     */
    Geolocation.prototype.update = function (position) {
        w.Tollwerk.position = position.coords;
        this.callbacks.forEach(cb => cb(position));
    }

    // Export as CommonJS module
    if (typeof exports !== 'undefined') {
        exports.Geolocation = new Geolocation();

        // Else create a global instance
    } else {
        w.Tollwerk.Geolocation = new Geolocation();
    }

    // Hook to the geolocation API
    if ('geolocation' in navigator) {
        navigator.geolocation.watchPosition(w.Tollwerk.Geolocation.update.bind(w.Tollwerk.Geolocation));
    }
})(typeof global !== 'undefined' ? global : window, document);
