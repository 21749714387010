'use strict';

(function (w, d) {
    w.Tollwerk.Observer.register('#filterForm-filter_range', function (rangeSlider) {
        const rangeSliderLabel = d.querySelector('.FormRange__label--outer');
        const rangeSliderSelector = d.querySelector('#filterForm-filter_limitDistance');

        // Set initial values
        if (rangeSlider && rangeSliderLabel) {
            rangeSliderLabel.setAttribute('data-current', rangeSliderLabel.getAttribute('data-min'));
            rangeSlider.disabled = true;

            rangeSliderSelector.addEventListener('change', function () {
                rangeSlider.disabled = !rangeSliderSelector.checked;
            });

            rangeSlider.addEventListener('input', function (event) {
                var rangeSliderValue = event.target.value;
                rangeSlider.setAttribute('data-current', rangeSliderValue);
                rangeSliderLabel.setAttribute('data-current', rangeSliderValue);
            });
        }
    });
})(window, document);
