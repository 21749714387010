(function (w) {
    'use strict';

    /**
     * Bind Proposal Update buttons
     *
     * @param {Element} targetElement
     */
    w.Tollwerk.bindProposalUpdate = function (targetElement) {
        // Utilize the FormHandler to request data-modal-url content
        const requestHandler = new w.Tollwerk.FormHandler();

        // Bind click event to targetElement
        targetElement.addEventListener('click', function (event) {
            event.preventDefault();
            requestHandler.makeRequest(this.getAttribute('data-modal-url'), function (data) {
                w.Tollwerk.Lightbox({ closeOnOutsideClick: true, theme: 'light' }).open(data);
            });
        });
    }

})(typeof global !== 'undefined' ? global : window);
