(function (w) {
    'use strict';

    w.Tollwerk.Observer.register('.Lightbox #frontendUserRegistration', function (observedElement) {

        const loginFormHandler = new w.Tollwerk.FormHandler(observedElement, observedElement.action);
        const nextButton = observedElement.querySelector('*[type="submit"]');

        // if (nextButton) {
        //     nextButton.addEventListener('click', function(event) {
        //         event.preventDefault();
        //         loginFormHandler.submitForm(nextButton.name, nextButton.value);
        //     });
        // }

        // TODO: Refactor (this and other forms?):
        /*
         Idea: Instead of listening for specific buttons (of which the css classes could change,
         we could search for the LAST input[type="submit"] and use it's name and value for the
         sumibtForm(button.name, button.value) call. We bind that to the form.submit event.
         Because that element will surely be the "next" or final submit button. So if the user
         presss ENTER to submit the form, it will always fire the next/submit, not the previous
         page (maybe because the prev button comes first in the HTML code).

         Additionally, if there is more than one submit button, the FIRST one will surely be
         the "previous" button. We can bind a click event on that one, to call form.submit().

         So in the end the resulting behaviour is like this:
            • ENTER will always go to the NEXT form page or submit the form
            • Clicking on the prev button will go to the previous form button as expected
         */


        observedElement.addEventListener('submit', function (event) {
            event.preventDefault();
            loginFormHandler.submitForm(nextButton.name, nextButton.value);
        });
    });

})(typeof global !== 'undefined' ? global : window);
