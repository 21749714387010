(function (w, d) {
    'use strict';

    /**
     * Initialize Location Discussion
     *
     * @param {Element} formElement
     */
    w.Tollwerk.initializeLocationDiscussion = function (formElement) {
        const discussionFormHandler = new w.Tollwerk.FormHandler(formElement, formElement.action);

        // Form Submit || Button Next
        const nextButton = formElement.querySelector('.FormButton--next');

        // Form Submit || Default
        formElement.addEventListener('submit', function (event) {
            event.preventDefault();
            discussionFormHandler.submitForm(
                nextButton.getAttribute('name'),
                nextButton.value,
                false
            );
        });

        // Check if Comments already exist
        const commentElement = d.querySelector('.CommentList');
        if (commentElement) {
            w.Tollwerk.initializeLocationDiscussionReply(commentElement);
        }
    };

    /**
     * Initialize Location Discussion Reply
     *
     * @param {Element} commentsElement
     */
    w.Tollwerk.initializeLocationDiscussionReply = function (commentsElement) {
        const replyButtons = commentsElement.querySelectorAll('.Comment__reply');

        if (replyButtons) {
            replyButtons.forEach(function (replyButton) {
                const replyFormHandler = new w.Tollwerk.FormHandler();

                replyButton.addEventListener('click', function (event) {
                    event.preventDefault();
                    const targetElement = replyButton.parentElement.parentElement.parentElement;

                    const href = replyButton.getAttribute('href').substring(1);
                    const url = (href.indexOf('location') > 0) ? 'location' + href : href;

                    const queryJSON = w.Tollwerk.queryStringToJSON(href);
                    const targetDiscussion = queryJSON['tx_twfh_locationdetails%5BreplyTo%5D'];

                    replyFormHandler.makeRequest('/' + url + '&type=2704', function (data) {
                        // Reply Form Content
                        const parser = new DOMParser();
                        const dom = parser.parseFromString(data, 'text/html');
                        const replyForm = dom.querySelector('.Form--reply');

                        if (replyForm) {
                            const replyReplyFormHandler = new w.Tollwerk.FormHandler(replyForm, replyForm.action);

                            // Reply form submit button
                            const nextButton = replyForm.querySelector('.FormButton--next');

                            // Reply form submit button event handler
                            replyForm.addEventListener('submit', function (event) {
                                event.preventDefault();

                                const observer = new MutationObserver(function (mutations) {
                                    mutations.forEach(function (mutation) {
                                        const discussionThread = d.querySelector('#reply-' + targetDiscussion);
                                        if (discussionThread) {
                                            const btnShow = discussionThread.parentElement.querySelector('.Comment__toggle-replies-show');
                                            if (btnShow) {
                                                btnShow.click();
                                            }
                                        }
                                    })
                                });

                                observer.observe(d.querySelector('.CommentList'), {
                                    childList: true,
                                    subtree: true
                                });

                                replyReplyFormHandler.submitForm(
                                    nextButton.getAttribute('name'),
                                    nextButton.value,
                                    false
                                );
                            });

                            // Append the partial into DOM
                            targetElement.appendChild(replyForm);

                            // Show the replies | Toggle replies button
                            const btnShow = targetElement.querySelector('.Comment__buttons .Comment__toggle-replies-show');
                            if (btnShow) {
                                btnShow.click();
                            }

                            // Scroll to form text area
                            const formTextElement = targetElement.querySelector('.FormTextarea');
                            if (formTextElement) {
                                formTextElement.scrollIntoView({
                                    behavior: 'smooth'
                                });
                            }

                            // Focus on the Text Area when scrolling is done
                            const observer = new IntersectionObserver(function (entries) {
                                let [entry] = entries;
                                if (entry.isIntersecting) {
                                    setTimeout(function () {
                                        const formTextArea = targetElement.querySelector('#commentReplyForm-text');
                                        if (formTextArea) {
                                            formTextArea.focus();
                                        }
                                    }, 100);
                                }
                            });
                            observer.observe(formTextElement);

                        }

                    });
                });
            });

            w.Tollwerk.initializeLocationReplies(commentsElement);
        }
    };

    /**
     * Initialize Location Replies
     *
     * @param {Element} commentsElement
     */
    w.Tollwerk.initializeLocationReplies = function (commentsElement) {
        const commentListItems = commentsElement.querySelectorAll('.CommentList__item');
        if (commentListItems) {
            commentListItems.forEach(function (commentListItem) {
                const btnShow = commentListItem.querySelector('.Comment__buttons .Comment__toggle-replies-show');
                const btnHide = commentListItem.querySelector('.Comment__buttons .Comment__toggle-replies-hide');
                if (btnShow && btnHide) {
                    const repliesElement = commentListItem.querySelector('ol .CommentList__items--replies');

                    btnShow.addEventListener('click', function (event) {
                        event.preventDefault();
                        // Button Display
                        btnHide.style.display = 'flex';
                        btnShow.style.display = 'none';

                        // Aria Attribute
                        btnHide.setAttribute('aria-expanded', true);
                        btnShow.setAttribute('aria-expanded', false);

                        repliesElement.style.display = 'block';
                    });

                    btnHide.addEventListener('click', function (event) {
                        event.preventDefault();
                        // Button Display
                        btnHide.style.display = 'none';
                        btnShow.style.display = 'flex';

                        // Aria Attribute
                        btnHide.setAttribute('aria-expanded', false);
                        btnShow.setAttribute('aria-expanded', true);

                        repliesElement.style.display = 'none';
                    });
                }
            });
        }
    };


    /**
     * Query String to JSON
     *
     * @param {String} queryString
     */
    w.Tollwerk.queryStringToJSON = function (queryString) {
        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    };

})(typeof global !== 'undefined' ? global : window, document);
