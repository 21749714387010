(function (w, d) {
    'use strict';

    /**
     * Constructor
     *
     * @param {String}  provider Map Provider name (decorational)
     * @param {Element} mapId Map Element
     * @param {String} zoom Default zoom value
     * @param {Array} coords Default coords value
     * @param {Object} options Default configuration
     * @param {Boolean} bootstrap Flag for bootstrapping
     * @constructor
     */
    const Map = function (provider, mapId, zoom, coords = [0, 0], options = {}, bootstrap = true) {
        var that = this;
        // Custom Markers
        this.customMarkers = {
            userMarker: L.divIcon({
                className: 'LocationMap__marker Map-Marker-Person',
                iconSize: [34, 45],
                iconAnchor: [17, 45]
            }),
            mapMarker0: L.divIcon({
                className: 'LocationMap__marker Map-Marker-Alt-0',
                iconSize: [34, 45],
                iconAnchor: [17, 45]
            }),
            mapMarker1: L.divIcon({
                className: 'LocationMap__marker Map-Marker-Alt-1',
                iconSize: [34, 45],
                iconAnchor: [17, 45]
            }),
            mapMarker2: L.divIcon({
                className: 'LocationMap__marker Map-Marker-Alt-2',
                iconSize: [34, 45],
                iconAnchor: [17, 45]
            }),
        };

        // Provider
        this.provider = provider; // TODO: qualify usage

        // MapId
        this.mapId = mapId;

        // Zoom
        this.zoom = zoom;
        this.geoLocationEnabled = false;

        // Coords
        this.longitude = coords[0];
        this.latitude = coords[1];

        // Options
        this.options = options;

        // Internals
        this.state = 'map';
        this.userLocation = [];
        this.userMarker = null;
        this.locationMarkers = [];

        // Initial configuration
        this.map = L.map(this.mapId, {
            layers: [
                L.tileLayer(this.options.urlTemplate, this.options)
            ],
            minZoom: 3,
            zoom: this.zoom,
            maxBounds: [[-90, -180], [90, 180]],
            load: function() {
                alert("load");
            }
        });

        // Additional initialization when required
        if (bootstrap) {
            // Leaflet's handling of geolocation
            this.map.locate({
                // setView: true,
                watch: true,
                // timeout: 60000,
                // minZoom: this.zoom,
                // maxZoom: this.zoom,
                maximumAge: 60000,
                enableHighAccuracy: true
            });

            // LeafletJS 'locationfound' event handler & Form event handlers
            that.bootstrapIt();

            // Initial location request
            that.updateMap();
        }

        // For Dev only
        // this.map.on('zoomend', function () {
        //     console.log('current zoom:', that.map.getZoom());
        // });

        // Update locate to stop settings the view when user scrolls on map
        this.map.on('dragend', function () {
            that.map.locate({ setView: false });
        });
    };

    /**
     * WIP Re-center Controller
     */
    Map.prototype.reCenterCtrl = function () {
        var that = this;

        // Insert controller when user location is enabled
        if (that.geoLocationEnabled) {
            // Extra controller
            L.Control.ReCenterController = L.Control.extend({
                onAdd: function (map) {
                    let controller = L.DomUtil.create('a', 'Userlocation__control');
                    controller.style.cursor = 'pointer';
                    controller.innerHTML = '<img src="/typo3conf/ext/tw_fh/Resources/Public/Icons/Map-Crosshairs.svg" width="512" height="96" alt="crosshair">';
                    controller.addEventListener('click', function () {
                        that.setUserLocation(that.userMarker._latlng.lat, that.userMarker._latlng.lng);
                        map.setView(that.userMarker._latlng, that.zoom, { animate: true });
                    });

                    return controller;
                },

                onRemove: function (map) {
                    // Nothing to do here
                }
            });

            L.control.ReCenterController = function (opts) {
                return new L.Control.ReCenterController(opts);
            };

            L.control.ReCenterController({
                position: 'topleft'
            }).addTo(that.map);
        }
    };

    /**
     * Add Detail Panel
     *
     * @param {String} type Type of detail panel
     * @param {Integer} uid Location or Project ID
     */
    Map.prototype.addDetailPanel = function (type, uid) {
        const typeConfig = {
            'location': {
                'query': {
                    'type': 2701,
                    'param': 'tx_twfh_locationdetails[location]'
                },
                'className': 'LocationCard__details',
                'position': 2,
                'buttonClass': 'LocationDetails__close'
            },
            'project': {
                'query': {
                    'type': 2702,
                    'param': 'tx_twfh_projectdetails[project]'
                },
                'className': 'ProjectCard__details',
                'position': 3,
                'buttonClass': 'ProjectDetails__close'
            }
        };

        // URI path
        const sQuery = 'type=' + typeConfig[type].query.type + '&' + typeConfig[type].query.param + '=' + uid;
        const that = this;

        // Removed projects when a new location panel is requested
        if (type === 'location') {
            this.resetDetailPanel('project');
        }

        // Ajax call
        this.makeRequest(null, sQuery, function (data) {
            const panelContent = data;
            const targetNode = d.querySelector('.PageMain .Location');

            // Detail panel element
            const detailPanel = d.createElement('div');
            detailPanel.className = typeConfig[type].className;
            detailPanel.innerHTML = (panelContent) ? panelContent.trim() : 'Error: ' + sQuery;

            // Append Detail Panel
            targetNode.insertBefore(detailPanel, targetNode.childNodes[typeConfig[type].position]);
            d.documentElement.classList.add(type + '-open');

            // Detail panel close button
            const closeBtn = detailPanel.querySelector('.' + typeConfig[type].buttonClass);
            closeBtn.addEventListener('click', function (event) {
                event.preventDefault();
                that.resetDetailPanel(type);

                // Close project detail panel in the case that it's open
                if (type === 'location') {
                    that.resetDetailPanel('project');
                }
            });

            // Detail panel edit button
            const editBtn = detailPanel.querySelector('a[data-modal-url]');
            if (editBtn) {
                const modalUrl = editBtn.getAttribute('data-modal-url');
                editBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    that.makeRequest(modalUrl, null, function (data) {
                        w.Tollwerk.Lightbox({ closeOnOutsideClick: true, theme: 'light' }).open(data);
                    });
                });
            }

            // Detail panel favorite button
            const favoriteBtn = detailPanel.querySelector('.LocationFavoritesButton');
            if (favoriteBtn) {
                const currentLanguage = d.documentElement.getAttribute('lang');

                // TODO: refactor using the `data-modal-url` pattern
                // const modalUrl = favoriteBtn.getAttribute('data-modal-url');
                // const modalUrl = '/location' + favoriteBtn.getAttribute('href').substring(1) + '&type=2701';
                const modalUrl = '/location' + favoriteBtn.getAttribute('href');

                favoriteBtn.addEventListener('click', function (event) {
                    event.preventDefault();

                    that.makeRequest(modalUrl, null, function (data) {
                        let messageIndex;
                        const buttonMessages = {
                            'en': ['Remove from favorites', 'Add to favorites'],
                            'de': ['Von Favoriten entfernen', 'Zu den Favoriten hinzufügen']
                        };

                        // Toggle btn
                        if (favoriteBtn.classList.contains('LocationFavoritesButton--add')) {
                            favoriteBtn.classList.remove('LocationFavoritesButton--add');
                            favoriteBtn.classList.add('LocationFavoritesButton--remove');
                            messageIndex = 0;
                        } else {
                            favoriteBtn.classList.remove('LocationFavoritesButton--remove');
                            favoriteBtn.classList.add('LocationFavoritesButton--add');
                            messageIndex = 1;
                        }

                        // Update the location list when a favorite is removed and the user is viewing their favorites
                        // if (d.documentElement.classList.contains('favorites-open')) {
                        //     that.showFavorites(d.querySelector('.FooterIcon__all'));
                        // }

                        // Update the button label
                        const ctaLabel = favoriteBtn.querySelector('.CallToAction__label');
                        if (ctaLabel) {
                            ctaLabel.innerHTML = buttonMessages[currentLanguage][messageIndex];
                        }
                    });
                });
            }

            // Initialize discussion component
            const discussForm = detailPanel.querySelector('#commentForm');
            if (discussForm) {
                w.Tollwerk.initializeLocationDiscussion(discussForm);
            }

            // Initialize contact owner form
            const contactOwnerForm = detailPanel.querySelector('#contactOwnerForm');
            if (contactOwnerForm) {
                w.Tollwerk.initializeContactOwnerForm(contactOwnerForm);
            }

            // Initialize slider component
            if (detailPanel.querySelectorAll('.Slider--item').length > 1) {
                initialiseSlider('.' + typeConfig[type].className);
            }

            // Initlalize Tablist component
            if (type === 'location') {
                TwTabList();
            }

            // Prep for Projects
            if (detailPanel.querySelectorAll('.ProjectList__item')) {
                that.bindProjectCards();
            }

            // Prep for Proposal
            const proposalButtons = d.querySelectorAll('.LocationProposals__list-item a[data-modal-url]');
            if (proposalButtons) {
                let btnIndex = 0;
                proposalButtons.forEach(function (proposalButton) {
                    if (btnIndex > 0) {
                        w.Tollwerk.bindProposalUpdate(proposalButton);
                    }
                    ++btnIndex;
                });
            }

            // Reset map bounds
            that.updateBounds(true);
            // that.updateBounds();
        });
    };

    /**
     * Initialize the list toggle buttons
     *
     * @param {Element} footer Footer element
     */
    Map.prototype.initializeFavoriteButtons = function (footer) {
        if(footer) {
            footer.querySelectorAll('.FooterIcon__all, .FooterIcon__favorites').forEach(link => {
                link.addEventListener('click', event => {
                    event.preventDefault();
                    this.showFavorites(footer);
                });
            })
        }
    };

    /**
     * Bind Form Listeners
     */
    Map.prototype.bindFormListeners = function () {
        const that = this;

        // Footer icons show favorites
        const locationFooter = d.getElementById('location-footer');
        this.initializeFavoriteButtons(locationFooter);

        /**
         * Form submission callback
         *
         * @param {Event} event Event
         */
        const submitCallback = event => {
            event.preventDefault();

            d.querySelector('.LocationSearch__search-input').blur(); // For mobile users
            this.resetFavoritesButton(locationFooter);
            this.updateMap();
        };

        /**
         * Checkbox change callback
         *
         * @param {Event} event Event
         */
        const checkboxCallback = event => {
            // Recenter if limit search results within distance is selected
            if (event.target.id === 'filterForm-filter_limitDistance') {
                if (event.target.checked) {
                    this.geoLocationEnabled ?
                        this.setUserLocation(that.userLocation[0], that.userLocation[1]) :
                        this.setUserLocation(that.latitude, that.longitude);
                }
                this.updateBounds();
            }

            // Filter scroll position
            const filterElement = d.querySelector('.LocationSearch__form .Form__body');
            if (filterElement) {
                filterElement.scrollTop = 0;
            }

            // Event handlers
            event.preventDefault();
            this.updateFilterCount();
            this.resetFavoritesButton(locationFooter);
            this.updateMap();
        };

        const searchInputs = d.querySelectorAll('[type=search]');
        searchInputs.forEach(search => search.addEventListener('change', event => {
            const value = search.value;
            searchInputs.forEach(s => {
                if (s !== search) {
                    s.value = value;
                }
            });
        }));

        // Search Form
        d.querySelectorAll('.LocationSearch__form').forEach(form => {
            /**
             * Form reset callback
             *
             * @param {Event} event Event
             */
            const resetCallback = event => {
                event.preventDefault();

                form.reset();
                form.querySelector('.FormRange__label--outer').setAttribute('data-current', 6);
                form.querySelector('#filterForm-filter_range').disabled = true;

                // Scroll filter to top
                d.querySelector('.LocationSearch__form .Form__body').scrollTop = 0;

                this.updateFilterCount();
                this.resetFavoritesButton(locationFooter);
                this.updateMap();
            };

            form.addEventListener('submit', this.updateMap);
            form.querySelectorAll('[type=submit]').forEach(submit => submit.addEventListener('click', submitCallback));
            form.querySelectorAll('[type=reset]').forEach(reset => reset.addEventListener('click', resetCallback));
            form.querySelectorAll('[type=range]').forEach(range => range.addEventListener('change', () => this.updateMap()));
            form.querySelectorAll('[type=checkbox]').forEach(checkbox => checkbox.addEventListener('change', checkboxCallback));
        });

        // Location cards event listeners
        this.bindLocationCards();
    };

    /**
     * Bind Location Cards
     */
    Map.prototype.bindLocationCards = function () {
        const oItems = d.querySelectorAll('.LocationCard');
        const that = this;
        for (let i = 0; i < oItems.length; i++) {
            oItems[i].addEventListener('click', function (event) {
                event.preventDefault();
                const uid = event.currentTarget.getAttribute('data-uid');

                // Get coords for map view
                let sQuery = 'type=2700&tx_twfh_locationlist[location]=list';
                if (w.Tollwerk.position) {
                    sQuery += '&tx_twfh_locationlist[latitude]=' + w.Tollwerk.position.latitude + '&tx_twfh_locationlist[longitude]=' + w.Tollwerk.position.longitude;
                }

                // Ajax request
                that.makeRequest(null, sQuery, function (data) {
                    let locationItem;
                    const oData = JSON.parse(data);
                    const locationList = oData.data.locations;
                    for (let ii = 0; ii < locationList.length; ii++) {
                        if (locationList[ii].uid === parseInt(uid)) {
                            locationItem = locationList[ii];
                        }
                    }
                    that.selectedMarker = new L.LatLng(locationItem.latitude, locationItem.longitude);
                });

                // Append the detail panel
                setTimeout(function () {
                    that.expandIt('location', parseInt(uid));
                }, 400);
            })
        }
    };

    /**
     * Bind Project Cards
     */
    Map.prototype.bindProjectCards = function () {
        const oItems = d.querySelectorAll('.ProjectList__item-link');
        const that = this;
        for (let i = 0; i < oItems.length; i++) {
            oItems[i].addEventListener('click', function (event) {
                event.preventDefault();
                const uid = event.currentTarget.getAttribute('data-uid');
                that.expandIt('project', parseInt(uid));
            });
        }

        // Project tabs
        const tabs = d.querySelectorAll('.Tabs__nav-item');
        tabs.forEach(function (tab) {
            tab.querySelector('a').addEventListener('click', function (event) {
                const label = event.target.getAttribute('id').split('-')[1];
                if (label !== 'projects') {
                    that.resetList('project');
                    that.resetDetailPanel('project');
                }
            })
        });
    };

    /**
     * Bootstrap the Map
     */
    Map.prototype.bootstrapIt = function () {
        const latitude = w.Tollwerk.position ? w.Tollwerk.position.latitude : this.latitude;
        const longitude = w.Tollwerk.position ? w.Tollwerk.position.longitude : this.longitude;

        // Define and create user marker
        this.userMarker = this.createMarker('000', '', {
            lat: latitude,
            lon: longitude
        }, this.customMarkers.userMarker, 9999);
        this.map.setView({ lat: latitude, lon: longitude }, this.zoom);
        this.setUserLocation(latitude, longitude);

        // Leaflet geolocation event handling
        const that = this;
        this.map.on('locationfound', function (e) {
            that.setUserLocation(e.latlng.lat, e.latlng.lng);
            that.userMarker.setLatLng(e.latlng);
        });

        // this is where the center button should be
        this.reCenterCtrl();

        // Bind Form listeners
        this.bindFormListeners();
    };

    /**
     * Add toggle listeners
     */
    Map.prototype.addEventListeners = function () {
        const that = this;
        const btnListView = d.querySelector('.FooterIcon__view--list');
        const btnMapView = d.querySelector('.FooterIcon__view--map');

        // Event Handlers
        if(btnListView) {
            btnListView.addEventListener('click', function (event) {
                event.preventDefault();
                that.toggleView('list');
            });
        }

        if(btnMapView) {
            btnMapView.addEventListener('click', function (event) {
                event.preventDefault();
                that.resetDetailPanel('location');
                that.toggleView('map');
                that.updateBounds();
            });
        }
    };

    /**
     * Build Markers
     *
     * @param {Object} data Markers data object
     */
    Map.prototype.buildMarkers = function (data) {
        const that = this;

        // Clear out internal tracking array
        that.locationMarkers = [];

        // Build marker and push to locationMakers array
        data.forEach(function (data) {
            const className = 'mapMarker' + data.status;
            const fullAddress = data.address;
            const markerLabel = (data.name !== '') ? data.name + ' – ' + fullAddress : fullAddress;
            const marker = that.createMarker(data.uid, markerLabel, [data.latitude, data.longitude], that.customMarkers[className], 0, data.uri);
            that.locationMarkers.push(marker);
        });
    };

    /**
     * Create Marker
     *
     * @param {String} markerId Marker Id
     * @param {String} markerLabel Marker label
     * @param {Array} coords Coordinates
     * @param {String} className CSS class name
     * @param {Integer} zIndexOffset Z-Index off set
     * @param {String} uri The uri for the location
     *
     * @returns {L} LeafletJS Marker
     */
    Map.prototype.createMarker = function (markerId, markerLabel, coords, className, zIndexOffset, uri) {
        const that = this;
        const markerTitle = (markerLabel !== '') ? markerLabel : 'Me!';

        // Create the marker element
        return new L.marker(coords, {
            id: markerId,
            icon: className,
            title: markerTitle,
            alt: markerTitle,
            zIndexOffset: zIndexOffset
        })
            .addTo(that.map)
            .addEventListener('keypress', function (event) {
                const whichKey = event.originalEvent.which;
                if (whichKey === 32 || whichKey === 13) {
                    that.selectedMarker = new L.LatLng(event.target._latlng.lat, event.target._latlng.lng);
                    setTimeout(function () {
                        that.expandIt('location', event.target.options.id);
                    }, 400);
                }
            })
            .on('click', function (event) {
                // If it ain't the marker for the user's position..
                if (markerId !== '000') {
                    // Check if the location panel exists.
                    const locationPanel = document.getElementById('location-panel');
                    if(locationPanel) {
                        // If location panel exists, activate the single view
                        that.selectedMarker = new L.LatLng(event.target._latlng.lat, event.target._latlng.lng);
                        setTimeout(function () {
                            that.expandIt('location', event.target.options.id);
                        }, 400);
                    } else {
                        // If location panel does not exist, we can assume that the
                        // map is shown in a standalone view, maybe an iframe. If that's
                        // the case, open wthe uri of the location details page in a new window.
                        window.open(uri);
                    }
                }
            })
            .on('mouseover', function (event) {
                if (markerId !== '000') {
                    that.locationHover('on', event.target.options.id);
                }
            })
            .on('mouseout', function (event) {
                if (markerId !== '000') {
                    that.locationHover('off', event.target.options.id)
                }
            });
    };

    /**
     * Create Property Marker (used in proposal form)
     *
     * @param {Array} coords Coordinates
     * @param {String} className CSS class name
     */
    Map.prototype.createPropertyMarker = function (coords, className) {
        const that = this;

        // Create the marker element
        new L.marker(coords, {
            id: 0,
            icon: className,
            title: 'Property',
            alt: 'Property - Drag & Drop',
            draggable: true
        })
            .addTo(that.map)
            .on('drag', function (e) {
                that.updatePropertyCoords([e.latlng.lat, e.latlng.lng]);
            })
            .on('dragend', function (e) {
                that.setPropertyView([e.target._latlng.lat, e.target._latlng.lng], that.map.getZoom());
            });
    };

    /**
     * Loads either Location Card or Project Card
     *
     * @param {String} type Type of detail card
     * @param {Integer} uid Location or Project ID
     */
    Map.prototype.expandIt = function (type, uid) {
        const typeConfig = {
            'location': ['LocationCard', 'LocationCard__details'],
            'project': ['ProjectList__item-link', 'ProjectCard__details']
        };

        // Stop looking for position
        this.map.locate({ setView: false });

        // Detail panel trigger
        const oCard = (type === 'location') ? this.getLocationCardById(uid) : this.getProjectCardById(uid);
        if(oCard) {
            if (!oCard.classList.contains(typeConfig[type][0] + '--active')) {
                // Reset list by type
                this.resetList(type);

                oCard.setAttribute('data-current', 'true');
                oCard.classList.add(typeConfig[type][0] + '--active');

                // Proceed to add detail panel
                const oItemDetails = d.querySelectorAll('.' + typeConfig[type][1]);
                if (oItemDetails.length) {
                    oItemDetails[0].remove();
                    // Append the detail panel
                    this.addDetailPanel(type, uid);
                } else {
                    // Append the detail panel
                    this.addDetailPanel(type, uid);
                }
            }
        }
    };

    /**
     * Return configuration longitude and latitude array
     *
     * @returns {{lng: (*|Integer), lat: (*|Integer)}} Center point
     */
    Map.prototype.getMapCenter = function () {
        return L.latLng(this.latitude, this.longitude);
    };

    /**
     * Returns marker array based on its ID
     *
     * @param {Integer} id Marker ID
     *
     * @returns {Array}
     */
    Map.prototype.getMarkersById = function (id) {
        const markers = [];
        this.locationMarkers.map(function (marker) {
            if (marker.options.id === id) {
                markers.push(marker);
            }
        });

        return markers;
    };

    /**
     * Returns location card based on its ID
     *
     * @param {Integer} uid Location card ID
     *
     * @returns {Element}
     */
    Map.prototype.getLocationCardById = function (uid) {
        const oCards = d.querySelectorAll('.LocationCard');
        let oCardElement;
        oCards.forEach(function (oCard) {
            if (parseInt(oCard.getAttribute('data-uid')) === uid) {
                oCardElement = oCard;
            }
        });
        return oCardElement;
    };

    /**
     * Return project card based on its ID
     *
     * @param {Integer} uid Project card ID
     *
     * @returns {Element}
     */
    Map.prototype.getProjectCardById = function (uid) {
        const oCards = d.querySelectorAll('.ProjectList__item-link');
        let oCardElement;
        oCards.forEach(function (oCard) {
            if (parseInt(oCard.getAttribute('data-uid')) === uid) {
                oCardElement = oCard;
            }
        });
        return oCardElement;
    };

    /**
     * Creates an XHR request
     *
     * @returns {*}
     */
    Map.prototype.getXHR = function () {
        // Ref: https://stackoverflow.com/questions/23272611/make-cors-ajax-requests-using-xmlhttprequest
        try {
            return new XMLHttpRequest();
        } catch (e) {
        }
        try {
            return new ActiveXObject("Msxml2.XMLHTTP.6.0");
        } catch (e) {
        }
        try {
            return new ActiveXObject("Msxml2.XMLHTTP.3.0");
        } catch (e) {
        }
        try {
            return new ActiveXObject("Microsoft.XMLHttp");
        } catch (e) {
        }
        // console.err("Could not find XMLHttpRequest");
    };

    /**
     * Adds hover class
     *
     * @param {String} state Hover state
     * @param {Integer} uid Location ID
     */
    Map.prototype.locationHover = function (state, uid) {
        const toggleStates = ['off', 'on'];
        const oCard = this.getLocationCardById(uid);

        // If a "card" for this marker is found, highlight it
        if(oCard) {
            if (toggleStates.indexOf(state)) {
                oCard.classList.add('LocationCard--hover');
                oCard.focus();
            } else {
                if (oCard.getAttribute('data-current') !== 'true') {
                    oCard.classList.remove('LocationCard--hover');
                    oCard.blur();
                }
            }
        }

    };

    /**
     * Makes a XHR request
     *
     * @param {String} url URL
     * @param {String} query Query
     * @param {Function} callback Callback function
     */
    Map.prototype.makeRequest = function (url, query, callback) {
        this.toggleLoader('on');
        url = url || d.location.href;
        if (query) {
            url = url.split('?').shift() + '?' + query;
        }

        const xhr = this.getXHR();
        xhr.open('GET', url);
        xhr.onload = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    callback(xhr.responseText);
                    this.toggleLoader('off');
                } else {
                    console.error(xhr.statusText);
                }
            }
        };

        xhr.onerror = function (e) {
            console.error(xhr.statusText);
        };

        xhr.send();
    };

    /**
     * Remove markers from map
     */
    Map.prototype.removeMarkers = function () {
        const that = this;

        // Reset internal tracker
        that.locationMarkers = [];

        // Remove makers from map
        this.map.eachLayer(function (layer) {
            if (layer.options.id !== '000' && layer.options.id !== 'mapbox.streets') {
                that.map.removeLayer(layer);
            }
        });
    };

    /**
     * Removed detail panel partial
     *
     * @param {String} type Detail panel ID
     */
    Map.prototype.resetDetailPanel = function (type) {
        const typeConfig = {
            'location': ['location-open', 'LocationCard'],
            'project': ['project-open', 'ProjectCard']
        };

        d.documentElement.classList.remove(typeConfig[type][0]);
        const detailPanel = d.querySelector('.' + typeConfig[type][1] + '__details');
        if (detailPanel) {
            detailPanel.parentNode.removeChild(detailPanel);
            this.updateBounds(true);
        }
        this.resetList(type);
    };

    /**
     * Reset location list
     *
     * @param {String} type Panel type
     */
    Map.prototype.resetList = function (type) {
        const typeConfig = {
            'location': 'LocationCard',
            'project': 'ProjectList__item-link'
        };

        const oCards = d.querySelectorAll('.' + typeConfig[type]);
        oCards.forEach(function (oCard) {
            oCard.removeAttribute('data-current');
            oCard.classList.remove(typeConfig[type] + '--active');
            oCard.classList.remove(typeConfig[type] + '--hover');
        });
    };

    /**
     * Set map location to property coordinates
     *
     * @param {Array} coords Property coordinates
     * @param {Integer} zoom Property map zoom
     */
    Map.prototype.setPropertyView = function (coords, zoom) {
        this.map.setView(coords, zoom);
    };

    /**
     * Set user location from 'locationfound'
     *
     * @param {Integer} latitude Latitude
     * @param {Integer} longitude Longitude
     */
    Map.prototype.setUserLocation = function (latitude, longitude) {
        this.geoLocationEnabled = true;
        this.longitude = longitude;
        this.latitude = latitude;
        this.userLocation = [latitude, longitude];
    };

    /**
     * TwFilter toggle
     *
     * @param {*} event
     */
    Map.prototype.toggleFilter = function (event) {
        // TODO: qualify usage
        if (event) {
            event.preventDefault();
        }
        TwFilter.toggle();
    };

    /**
     * Toggle Loader Animation
     *
     * @param {String} state Loading animation state
     */
    Map.prototype.toggleLoader = function (state) {

        // DOM elements
        const searchInput = d.querySelector('.LocationSearch');
        const searchFilter = d.querySelector('.LocationSearch--filter .Form__body');
        const locationList = d.querySelector('.LocationList');
        const locationMap = d.querySelector('#map'); // TODO: check z-index

        // DOM elements collection
        const containers = [searchInput, searchFilter, locationList, locationMap];

        for (let i = 0; i < containers.length; i++) {
            if (containers[i] !== null) {
                containers[i].classList[['remove', 'add'][(state === 'on') * 1]]('Loading');
            }
        }
    };
    /**
     * Toggle view
     *
     * @param {String} state View state
     */
    Map.prototype.toggleView = function (state) {

        const that = this;
        if (state !== 'reset') {
            that.state = state;
        }

        // Components
        const oLocationList = d.querySelector('.LocationList');
        const oMap = d.querySelector('.LocationMap');

        // Buttons
        const btnListView = d.querySelector('.FooterIcon__view--list');
        const btnMapView = d.querySelector('.FooterIcon__view--map');

        switch (state) {
            case 'list':
                // Location List
                if(oLocationList) {
                    oLocationList.classList.add('LocationList--enable');
                    oLocationList.classList.remove('LocationList--disable');
                }

                // Map
                if(oMap) {
                    oMap.classList.add('LocationMap--disable');
                    oMap.classList.remove('LocationMap--enable');
                }

                // Buttons
                if(btnListView) {
                    btnListView.style.display = 'none';
                }
                if(btnMapView) {
                    btnMapView.style.display = 'block';
                }
                break;

            case 'map':

                // Location List
                if(oLocationList) {
                    oLocationList.classList.add('LocationList--disable');
                    oLocationList.classList.remove('LocationList--enable');
                }

                // Map
                if(oMap) {
                    oMap.classList.add('LocationMap--enable');
                    oMap.classList.remove('LocationMap--disable');
                }

                // Buttons
                if(btnListView) {
                    btnListView.style.display = 'block';
                }
                if(btnMapView) {
                    btnMapView.style.display = 'none';
                }

                if (TwFilter.isActive()) {
                    that.toggleFilter();
                }
                break;

            case 'reset':
                // Location List
                if(oLocationList) {
                    oLocationList.classList.remove('LocationList--enable');
                    oLocationList.classList.remove('LocationList--disable');
                }

                // Map
                if(oMap) {
                    oMap.classList.remove('LocationMap--enable');
                    oMap.classList.remove('LocationMap--disable');
                }

                // Buttons
                if(btnListView) {
                    btnListView.style.display = 'none';
                }
                if(btnMapView){
                    btnMapView.style.display = 'none';
                }

                break;
        }
    };

    /**
     * Update map bounds
     *
     * @param {Boolean} focusMarker Boolean flag to focus on marker
     */
    Map.prototype.updateBounds = function (focusMarker = false) {

        // Collection of markers to fit into bounds
        let boundMarkers = [];

        // Boolean flag for adjusting zoom when no markers are near by
        let hasMarkersNearBy = false;

        // Boolean flag for validating if the updateBound call is with out a search query
        // TODO: Get Judith's help to apply proper unique IDs to the input fields, so that the query selector can be updated
        if(d.querySelector('.LocationSearch__search-input')) {
            let withOutSearch = !(d.querySelectorAll('.LocationSearch__search-input')[1].value !== '' && d.querySelectorAll('.LocationList__list-item').length > 0);
        }

        // Boolean flag to validate if the updateBound call is with out filter restrictions
        if(d.querySelector('.FormRange__label--outer')) {
            const withoutFilter = !this.getFilterCount();
            const limitDistance = d.getElementById('filterForm-filter_limitDistance').checked;
            const range = limitDistance ? parseInt(d.querySelector('.FormRange__label--outer').getAttribute('data-current')) : 0;
        }

        // Set center (from) location
        const from = this.userMarker ? this.userMarker.getLatLng() : this.getMapCenter();
        this.locationMarkers.forEach(function (locationMarker) {
            // Set marker (to) location
            const to = locationMarker.getLatLng();

            // Calculate distance from user maker to see if it should be included into the markerGroup
            const distance = (from.distanceTo(to).toFixed(0) / 1000);

            // Validate if it will be included in our collection
            if (Math.round(distance) <= (range || 6)) {
                boundMarkers.push(locationMarker);
                hasMarkersNearBy = true;
            }
        });

        // Build bound markers from location makers only when a valid search result
        if (withOutSearch) {
            // Without Search
            if (this.userMarker && boundMarkers.length > 0) {
                // Include the user marker
                boundMarkers.push(this.userMarker);
                this.locationMarkers.push(this.userMarker);
            }
        } else {
            // With Search
            if (this.userMarker && boundMarkers.length > 0) {
                // Include the user marker
                boundMarkers.push(this.userMarker);
                this.locationMarkers.push(this.userMarker);
            } else {
                boundMarkers = this.locationMarkers;
            }
        }

        // Focus single marker or group of markers
        if (focusMarker) {
            // Set map view to selected Marker
            this.map.setView(this.selectedMarker, this.zoom, { animation: true });
        } else {
            if (limitDistance) {
                // Clear radius layer
                if (this.map.diameter) {
                    this.map.removeLayer(this.map.diameter);
                }

                // TODO: Try to get the same behaviour with the circle when the geoLocation is enabled
                if (!hasMarkersNearBy) {
                    // Set range diameter
                    this.map.diameter = L.circle(from, range * 1000, { opacity: 0 }).addTo(this.map);
                    // Fit bounds
                    this.map.fitBounds(this.map.diameter.getBounds(), { padding: [-25, -25] });
                } else {
                    if (this.geoLocationEnabled) {
                        if (boundMarkers.length > 0) {
                            // Fit to bounds
                            let markerGroup = new L.featureGroup(boundMarkers);
                            this.map.fitBounds(markerGroup.getBounds(), { padding: [11, 11] });
                        }
                    } else {
                        if (boundMarkers.length > 0) {
                            // Fit to bounds
                            let markerGroup = new L.featureGroup(boundMarkers);
                            this.map.fitBounds(markerGroup.getBounds(), { padding: [11, 11] });
                        } else {
                            this.map.diameter = L.circle(from, range * 1000, { opacity: 0 }).addTo(this.map);
                            this.map.fitBounds(this.map.diameter.getBounds(), { padding: [-25, -25] });
                        }
                    }
                }

            } else {
                if (boundMarkers.length > 0) {
                    // Fit to bounds
                    let markerGroup = new L.featureGroup(boundMarkers);
                    this.map.fitBounds(markerGroup.getBounds(), { padding: [11, 11] });
                }
            }
        }

        // Adjust the zoom on the initial state if there are no markers near by
        if (withOutSearch && withoutFilter && !hasMarkersNearBy && !focusMarker) {
            this.map.setZoom(this.zoom - 3);
        }

        // LeafletJS method to reset the view
        if (withoutFilter) {
            this.map.invalidateSize(true);
        }
    };

    /**
     * Has user marker
     *
     * @param {Array} markers Collection of Markers
     *
     * @returns {boolean}
     */
    Map.prototype.hasUserMaker = function (markers) {
        markers.forEach(function (marker) {
            if (marker.options.id === '000') {
                return true;
            }
        });

        return false;
    };

    /**
     * Get filter count
     *
     * @return {Boolean} Number of activated filters
     */
    Map.prototype.getFilterCount = function () {
        let count = 0;
        const checkBoxes = d.querySelector('.LocationSearch__form .Form__body').querySelectorAll('input[type=checkbox]');

        checkBoxes.forEach(function (checkBox) {
            if (checkBox.checked) {
                ++count;
            }
        });

        return count;
    };

    /**
     * Updates filter count
     */
    Map.prototype.updateFilterCount = function () {
        let count = 0;
        const filterCheckboxes = d.querySelector('.LocationSearch__form .Form__body').querySelectorAll('input[type=checkbox]');

        // Filter counter
        filterCheckboxes.forEach(function (checkbox) {
            if (checkbox.checked) {
                count++;
            }
        });

        // Filter counter output
        const filterCountElement = d.querySelector('.FilterHead__counter');
        if (count === 0) {
            filterCountElement.innerHTML = '';
        } else {
            filterCountElement.innerHTML = '(' + count + ')';
        }
    };

    /**
     * Reset favorite button
     *
     * @param {Element} footer Location footer
     */
    Map.prototype.resetFavoritesButton = function (footer) {
        footer.classList.remove('LocationFooter--favorites');
        footer.classList.add('LocationFooter--default');
    };

    /**
     * Update map with the favorites
     *
     * @param {Element} footer Location footer
     */
    Map.prototype.showFavorites = function (footer) {
        const that = this;
        if (footer.classList.contains('LocationFooter--default')) {
            footer.classList.remove('LocationFooter--default');
            footer.classList.add('LocationFooter--favorites');

            // Handle the Filter
            d.querySelectorAll('form.LocationSearch__form').forEach((form) => {
                form.reset();
                that.updateFilterCount();
                if (TwFilter.isActive()) {
                    that.toggleFilter();
                }
            });

            // Make Request
            that.makeRequest(null, 'type=2700&tx_twfh_locationlist[showFavorites]=1', function (response) {
                const oList = d.querySelector('.LocationList');
                if (oList) {
                    const data = JSON.parse(response);

                    // Update results count label
                    const oLabel = d.querySelector('.FilterResultsWithIcon__label');
                    if (oLabel) {
                        oLabel.textContent = `${data.data.locations.length} ${oList.getAttribute('data-label-results')}`;
                    }

                    // Remove old markers
                    that.removeMarkers();

                    if (data.data.locations.length > 0) {
                        // Build new markers
                        that.buildMarkers(data.data.locations);
                    }

                    // Update List
                    oList.outerHTML = data.html;

                    // Bind Event Listener
                    that.bindLocationCards();
                    that.updateBounds();
                }
            });
        } else {
            this.resetFavoritesButton(footer);
            that.updateMap();
        }

        // Reset all the detail pannel
        that.resetDetailPanel('project');
        that.resetDetailPanel('location');
    };

    /**
     * Update map based on search and filter
     */
    Map.prototype.updateMap = function () {
        const that = this;

        // Reset Location Detail Panel
        if (d.querySelectorAll('.LocationCard__details').length) {
            this.resetDetailPanel('location');
        }

        // Query parameters
        const params = { type: 2700, tx_twfh_locationlist: {} };

        // Search Query
        const searchTerm = Array.prototype.slice.call(d.querySelectorAll('.LocationSearch__search-input'))
            .reduce((accumulator, search) => accumulator || search.value, '');
        if (searchTerm) {
            params.tx_twfh_locationlist.search = searchTerm;
        }

        // Position
        if (w.Tollwerk.position) {
            params.tx_twfh_locationlist.latitude = w.Tollwerk.position.latitude;
            params.tx_twfh_locationlist.longitude = w.Tollwerk.position.longitude;
        }

        // User geoData
        // if (that.geoLocationEnabled) {
        //     const center = that.getMapCenter();
        //     sQuery += '&tx_twfh_locationlist[longitude]=' + center.lng;
        //     sQuery += '&tx_twfh_locationlist[latitude]=' + center.lat;
        // }

        // Filter values
        const filterForm = document.getElementById('filter');
        if (filterForm) {
            const filter = { filter: { status: [], tags: [] } };
            const range = d.getElementById('filterForm-filter_range');
            if (range && !range.disabled) {
                filter.filter.range = range.value;
            }
            filterForm.querySelectorAll('#filterForm-filter_status [type=checkbox]').forEach(input => {
                if (input.checked) {
                    filter.filter.status.push(input.value);
                }
            });
            filterForm.querySelectorAll('#filterForm-filter_tags [type=checkbox]').forEach(input => {
                if (input.checked) {
                    filter.filter.tags.push(input.value);
                }
            });
            params.tx_twfh_locationlist.filterForm = filter;
        }

        // Run search
        this.makeRequest(null, String.toUri(params), function (response) {
            const oList = d.querySelector('.LocationList');
            if (oList) {
                const data = JSON.parse(response);

                // Update results count label
                const oLabel = d.querySelector('.FilterResultsWithIcon__label');
                if (oLabel) {
                    oLabel.textContent = `${data.data.locations.length} ${oList.getAttribute('data-label-results')}`;
                }

                // Remove old markers
                that.removeMarkers();

                // Build new markers
                data.data.locations.length && that.buildMarkers(data.data.locations);

                // Update List
                const nList = d.createElement('div');
                nList.innerHTML = data.html;
                oList.innerHTML = nList.children[0].innerHTML;

                // Bind Event Listener
                that.bindLocationCards();
                that.updateBounds();
            }
        });
    };

    /**
     * Update property coord
     *
     * @param {Array} coords
     */
    Map.prototype.updatePropertyCoords = function (coords) {
        d.getElementById('proposalForm-coordinates_latitude').value = coords[0].toFixed(6);
        d.getElementById('proposalForm-coordinates_longitude').value = coords[1].toFixed(6);
    };

    // Export as CommonJS module
    if (typeof exports !== 'undefined') {
        exports.twMap = Map;

        // Else create a global instance
    } else {
        w.Tollwerk.twMap = Map;
    }

})(typeof global !== 'undefined' ? global : window, document);
