'use strict';

(function (w) {
    w.Tollwerk.Observer.register('.Lightbox #proposalForm', function (observedElement) {
        const proposalFormHandler = new w.Tollwerk.FormHandler(observedElement, observedElement.action);

        // Form Submit || Button Next
        const nextButton = observedElement.querySelector('.FormButton--next');
        if (nextButton) {
            nextButton.addEventListener('click', function (event) {
                event.preventDefault();
                if (validateForm(observedElement)) {
                    proposalFormHandler.submitForm(nextButton.getAttribute('name'), nextButton.value);
                }
            });
        }

        // // Form Submit || Button Previous
        const prevButton = observedElement.querySelector('.FormButton--previous');
        if (prevButton) {
            prevButton.addEventListener('click', function (event) {
                event.preventDefault();

                if(prevButton.getAttribute('formnovalidate')){
                    proposalFormHandler.submitForm(prevButton.getAttribute('name'), prevButton.value);
                }

            });
        }

        // Update preview button
        const updatePreviewButton = observedElement.querySelector('.FormCoordinates__update-button');
        if (updatePreviewButton) {
            updatePreviewButton.style.display = 'none';
        }

        // Form Submit || Default
        observedElement.addEventListener('submit', function (event) {
            event.preventDefault();
            if (validateForm(observedElement)) {
                proposalFormHandler.submitForm(nextButton.getAttribute('name'), nextButton.value);
            }
        });

        const validateForm = function(targetForm){
            let validElements = [];

            const formElements = targetForm.querySelectorAll('[required]');
            const elementCount = formElements.length;

            formElements.forEach(function(formElement) {
                if (formElement.getAttribute('required') && formElement.checkValidity()) {
                    validElements.push(formElement);
                } else {
                    formElement.reportValidity();
                }
            });

            return (elementCount === validElements.length);
        };
    });

})(window);
