(function (w, d) {
    'use strict';

	window.addEventListener('load', function () {

        const mapId = 'map';
        const mapElement = d.getElementById(mapId);
        if (mapElement) {
            const mapZoom = d.getElementById(mapId).getAttribute('data-zoom');
            const mapCenter = window.Tollwerk.position ? [window.Tollwerk.position.longitude, window.Tollwerk.position.latitude] : eval(d.getElementById(mapId).getAttribute('data-center'));
            const mapOptions = {
                id: 'mapbox.streets',
                noWrap: true,
                inertia: true,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                accessToken: 'pk.eyJ1IjoidG9sbHdlcmsiLCJhIjoiY2pubjByanVhMDl6cjNwbWI2bmd3djJ1bCJ9.hW-3b9ymt8T64DNC-fE62w',
                urlTemplate: 'https://api.mapbox.com/styles/v1/tollwerk/cjnvqy0hi5r3i2rpe462gl6d7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidG9sbHdlcmsiLCJhIjoiY2tiMHg3MGZ1MDU4cTJxbGM2anJwem9uNiJ9.uN2tmZrJhsLPiqL3E5qiTQ',
                // urlTemplate: 'https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.png?access_token=sk.eyJ1IjoidG9sbHdlcmsiLCJhIjoiY2tiMXltdzRsMDRhazJ3czBybHdxNTl2cCJ9.lUUiy2PwDDpK5ePiuaMYMQ',
                // urlTemplate: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
                // urlTemplate: 'https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png',
                // urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                inertiaDeceleration: 2000,
            };

            const settings = new w.Tollwerk.twMap('OpenStreetMaps', mapElement, mapZoom, mapCenter, mapOptions);

            settings.addEventListeners();

            // Favorite Listing Handler
            settings.state = 'map';

            // Initial list request
            if (!window.location.search) {
                settings.makeRequest(null, 'type=2700', function (data) {
                    settings.buildMarkers(JSON.parse(data).data.locations);
                });
            } else {
                const searchValue = d.querySelector('.LocationSearch__search-input').value;

                // if (window.location.search.indexOf('showFavorites')) {
                //     settings.makeRequest('type=2700&tx_twfh_locationlist[showFavorites]=1', function (data) {
                //         console.log('feedback: ', JSON.parse(data).data.locations);
                //         settings.removeMarkers();
                //         settings.buildMarkers(JSON.parse(data).data.locations);
                //     });
                // } else {
                    settings.makeRequest(null, 'type=2700&tx_twfh_locationlist[search]=' + encodeURI(searchValue), function (data) {
                        settings.buildMarkers(JSON.parse(data).data.locations);
                    });
                // }

            }

            w.Tollwerk.BreakpointManager.registerComponent(
                settings,
                null,
                200,
                function () {
                    this.toggleView(this.state);
                    this.updateBounds();
                },
                function () {
                    this.toggleView('reset');
                    this.updateBounds();
                }
            );
        }
    });

})(typeof global !== 'undefined' ? global : window, document);
